<template>
  <svg
    width="6"
    height="12"
    viewBox="0 0 6 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.13514 0L0 1.4L3.72973 6L0 10.6L1.13514 12L6 6L1.13514 0Z"
      fill="#212429"
    />
  </svg>
</template>
<script>
export default {
   name: "CaretRightIcon"
}
</script>

