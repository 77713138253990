<template>
  <svg
    width="6"
    height="12"
    viewBox="0 0 6 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.86486 0L6 1.4L2.27027 6L6 10.6L4.86486 12L0 6L4.86486 0Z"
      fill="#212429"
    />
  </svg>
</template>
<script>
export default {
  name: "CaretLeftIcon",
};
</script>
